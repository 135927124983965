import * as React from 'react'
import { Grid } from '@material-ui/core'
import { SelectInput, NumberInput } from 'react-admin'

import PropTypes from 'prop-types'
import getConfig from './core_config'
import ConvertedPricesTooltip from './Tooltips/ConvertedPricesTooltip'

const PriceInput = props => {
  const {
    validate,
    priceSource,
    withCurrency = false,
    defaultCurrency,
    record
  } = props
  const [price, setPrice] = React.useState(record !== undefined && record[priceSource] !== undefined ? record[priceSource] : 0)
  const [currency, setCurrency] = React.useState(record !== undefined && record.currency !== undefined ? record.currency : defaultCurrency)
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <>
    <Grid container spacing={3} style={{ alignItems: 'center' }}>
      <Grid item xs={4}>
        <NumberInput source={priceSource} onChange={(e) => setPrice(e.target.value)} validate={validate} />
        </Grid>
      <Grid item xs={2}>
        {withCurrency
          ? <><SelectInput choices={config.currencies_choices} onChange={(e) => setCurrency(e.target.value)} source="currency" validate={validate} fullWidth/></>
          : <>{currency}</>}
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="vat_rate" validate={validate}/>
      </Grid>
      <Grid item xs={2}>
        <ConvertedPricesTooltip fieldValue={price} currency={currency}/>
      </Grid>
    </Grid>
    </>
  )
}

PriceInput.propTypes = {
  validate: PropTypes.func,
  priceSource: PropTypes.string,
  withCurrency: PropTypes.bool,
  defaultCurrency: PropTypes.string,
  record: PropTypes.object
}

export default PriceInput
