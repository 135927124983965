import * as React from 'react'

import {
  TextInput,
  required,
  ReferenceManyField,
  TextField,
  BooleanField,
  BooleanInput,
  NumberInput,
  NumberField
} from 'react-admin'

import { Grid } from '@material-ui/core'

import PropTypes from 'prop-types'
import PriceInput from '../../../Components/PriceInput'
import PriceField from '../../../Components/PriceField'
import { DatagridWithDialog } from '../../../Components/Dialogs'
import { ChannelTextInput } from '../../../Components/Inputs'

const SellPriceItemForm = (props) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <TextInput source="ident" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="sort_order" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <BooleanInput source="is_main" fullWidth />
        </Grid>
        <Grid item xs={5}>
          <BooleanInput source="is_buyable_alone" fullWidth />
        </Grid>
        <Grid item xs={3}>
          <NumberInput source="units" fullWidth />
        </Grid>
      </Grid>
      <PriceInput
        validate={required()}
        priceSource="price"
        defaultCurrency="CZK"
        {...props}
      />
      <Grid container spacing={3} style={{ alignItems: 'center' }}>
        <Grid item xs={4}>
           <NumberInput source="original_price" />
        </Grid>
        <Grid item xs={2}>
          CZK
        </Grid>
      </Grid>
      <ChannelTextInput label="title" source="content.title" editable {...props}/>
      <ChannelTextInput label="Price quantifier" source="content.price_quantifier" editable {...props}/>
      <ChannelTextInput label="subtitle" source="content.subtitle" editable {...props}/>
      <ChannelTextInput label="description_button" source="content.description_button" editable {...props}/>
      <ChannelTextInput label="description" source="content.description" editable {...props}/>
      <ChannelTextInput label="discount" source="content.discount" editable {...props}/>
      <TextInput source="ticket_attribute" fullWidth />
      <TextInput source="capacity_pool_tag" fullWidth />
      <TextInput label="filter_group" source="content.filter_group" fullWidth />
      <Grid container spacing={3} style={{ alignItems: 'center' }}>
        <Grid item xs={6}>
          <NumberInput label="Quantity min" source="content.range.boundary_inf" fullWidth />
        </Grid>
        <Grid item xs={6}>
      <NumberInput label="Quantity max" source="content.range.boundary_sup" fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

const SellPriceItemsEdit = (props) => {
  return (
    <ReferenceManyField
      label=""
      sort={{ field: 'sort_order', order: 'ASC' }}
      reference="sellpriceitem"
      target="service_offering_config"
      fullWidth
    >
      <DatagridWithDialog
        defaultValue={{
          currency: 'CZK',
          service_offering_config: props.record.id
        }}
        form={<SellPriceItemForm />}
      >
        <TextField source="ident" />
        <TextField source="sort_order" />
        <TextField source="ticket_attribute" />
        <TextField source="capacity_pool_tag" />
        <BooleanField source="is_main" />
        <BooleanField source="is_buyable_alone" />
        <PriceField label="Price" priceSource="price" />
        <NumberField label="Original price" source="original_price" />
      </DatagridWithDialog>
    </ReferenceManyField>
  )
}

SellPriceItemsEdit.propTypes = {
  record: PropTypes.object
}

SellPriceItemForm.propTypes = {
  initialValues: PropTypes.object
}

export default SellPriceItemsEdit
