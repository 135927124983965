import * as React from 'react'
import { Fragment, memo } from 'react'

import { Card, CardContent, Divider, Grid, Typography } from '@material-ui/core'

import {
  AutocompleteInput,
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TextInput,
  Pagination
} from 'react-admin' // eslint-disable-line import/no-unresolved

import { PropTypes } from 'prop-types'
import { ResetViewsButton } from '../../Components'
import ListActions from '../../Components/Toolbar/ListActions'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { FilterWithSave, useSelectedColumns } from '@react-admin/ra-preferences'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import GoToButton from './GoToButton'

import statusRowStyle from '../../Components/statusRowStyle'
import exporter from '../../Components/exporter'
import DateField from '../../Components/Datefield'
import LocalDate from '../../Components/LocalDate'
import ResendMessage from './ResendMessage'
import { supplierStatusColor } from '../../Utils/StatusColors'

const SupplierMessageFilter = props => (
  <FilterWithSave {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput source="status" />
    <ReferenceInput source="supplier" reference="supplier">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </FilterWithSave>
)

// eslint-disable-next-line react/display-name
const SupplierMessageListBulkActions = memo(props => (
  <Fragment>
    <ResetViewsButton {...props} />
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const SupplierMessageExpand = props => {
  return (
    <Grid container spacing={1}>
      {props.record.response_valid_until === null
        ? null
        : <Grid item xs={12}>
        <Typography variant="h6" style={{ marginLeft: 9, marginTop: 9 }}>Response valid until {LocalDate(props.record.response_valid_until)}</Typography>
      </Grid>
      }

      <Grid item xs={12}>
        <Typography variant="h6">
          {LocalDate(props.record.service_realization_detail.start, true)}
          &nbsp;&ndash;&nbsp;
          {props.record.service_realization_detail.service_base_detail.admin_name}
        </Typography>

        <Card variant="outlined">
          <CardContent style={{ padding: 16 }}>
            <span>Message sent: {LocalDate(props.record.sent_at, true)}</span>
            {props.record.message === null ? <p>No message text</p> : <div dangerouslySetInnerHTML={{ __html: props.record.message }} />}
            {props.record.status === 'pending'
              ? null
              : <>
              <Divider /><br/>
            <span>Response: {LocalDate(props.record.response_at, true)}</span><br/>
            {!props.record.response_text ? <p>No response text</p> : <p>{props.record.response_text}</p>}
            </>}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

const suppliermessageColumns = {
  id: <TextField source="id" />,
  supplier:
    <ReferenceField source="supplier" reference="supplier" link={false}>
      <TextField source="name"/>
    </ReferenceField>,
  status: <FunctionField source="status" render={record => <span style={{ color: supplierStatusColor(record.status) }}>{record.status}</span>} />,
  type: <TextField source="message_type" />,
  message: <FunctionField source="message" render={record => record.message === null ? null : record.message.replace(/<\/?[^>]+(>|$)/g, ' ').length > 30 ? record.message.replace(/<\/?[^>]+(>|$)/g, ' ').substring(0, 30) + '...' : record.message.replace(/<\/?[^>]+(>|$)/g, ' ') }/>,
  realization_at: <DateField label="Realization start" source="service_realization_detail.start" showTime />,
  service: <TextField label="Service" source="service_realization_detail.service_base_detail.admin_name" />,
  sent_at: <DateField source="sent_at" showTime />,
  response_at: <DateField source="response_at" showTime />,
  response_valid_until: <DateField source="response_valid_until" showTime />,
  secret: <TextField source="secret" />
}

const SupplierMessageList = props => {
  const columns = useSelectedColumns({
    preferences: 'suppliermessage.list.columns',
    columns: suppliermessageColumns,
    omit: ['id', 'secret', 'message_type', 'message', 'response_valid_until']
  })
  useDefineAppLocation('supplier.message')
  return (
    <>
      <List
        {...props}
        actions={<ListActions columnPreference="suppliermessage.list.columns" columns={suppliermessageColumns} />}
        bulkActionButtons={<SupplierMessageListBulkActions />}
        filters={<SupplierMessageFilter />}
        sort={{ field: 'sent_at', order: 'DESC' }}
        exporter={(data) => exporter(data, 'SupplierMessage')}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
        empty={false}
      >
        <Datagrid optimized expand={<SupplierMessageExpand />} rowStyle={(record) => record ? statusRowStyle(supplierStatusColor(record.status)) : null} >
          {columns}
          <ListActionToolbar>
            <ResendMessage {...props} />
            <GoToButton />
          </ListActionToolbar>
        </Datagrid>
      </List>
    </>
  )
}

SupplierMessageExpand.propTypes = {
  record: PropTypes.object.isRequired
}

export default SupplierMessageList
